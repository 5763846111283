<template>
  <div class="publications">
    <page-header :title="title" icon="fa fa-book-open-reader"></page-header>
    <loading-gif :loading-name="initLoadingName"></loading-gif>
    <b-row v-if="!isLoading(this.initLoadingName)">
      <b-col cols="8">
        <div>
          <ul class="app-menu">
            <li>
              <b-form @submit="onSearch" id="search-activity-form">
                <b-form-group
                  id="search-group"
                  label-for="search-activity"
                  description="Rechercher une activité à partir de son nom"
                >
                  <b-form-row>
                    <b-col cols="12">
                      <b-input-group prepend="Recherche" class="mt-3 input-group-primary">
                        <b-form-input
                          id="search-publications"
                          type="search"
                          placeholder="Entrez le nom d'un article, d'un auteur ou un mot-clef"
                          v-model="searchText"
                          autocomplete="off"
                          :class="{ filled: searchText }"
                        >
                        </b-form-input>
                        <b-input-group-append>
                          <b-button type="submit" variant="primary">
                            <i class="fa fa-search"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-form-row>
                </b-form-group>
              </b-form>
            </li>
            <li>
              <b-row>
                <b-col cols="9">
                  <b>
                    <counter-label :counter="publicationsCount" label="publication"></counter-label>
                  </b>
                </b-col>
                <b-col class="text-right">
                  <div v-if="showPublications">
                    <a class="btn btn-xs btn-back-all btn-secondary" href @click.prevent="onReset">
                       <i class="fa fa-chevron-left"></i> Retour
                     </a>
                  </div>
                </b-col>
              </b-row>
            </li>
            <li v-if="searchText && !isLoading(loadingName)">
              <b-row>
                <b-col cols="9">
                  <b>
                    Recherche:
                    <counter-label :counter="publications.length" label="publication"></counter-label>
                  </b>
                </b-col>
                <b-col class="text-right">
                  <div v-if="showPublications">
                    <a class="btn btn-xs btn-back-all btn-secondary" href @click.prevent="onReset">
                       <i class="fa fa-chevron-left"></i> Retour
                     </a>
                  </div>
                </b-col>
              </b-row>
            </li>
            <loading-gif :loading-name="loadingName"></loading-gif>
            <li
              v-for="publication of publications" :key="publication.id"
              :class="{ selected: selectedPublication && selectedPublication.id === publication.id }"
            >
              <b-row>
                <b-col cols="10">
                  <a href @click.prevent="viewPublication(publication)">
                    {{ publication.fullName() }}
                  </a>
                </b-col>
                <b-col class="text-right">
                  <div
                    v-if="hasPerm('publications.change_publication') && selectedPublication === publication"
                  >
                    <router-link :to="{ name: 'publications-edit', params: {'publicationId': publication.id, }}">
                      <i class="fa fa-chevron-right"></i> Voir
                    </router-link>
                  </div>
                </b-col>
              </b-row>
              <div>
                <span v-for="author of publication.authors" :key="author.id" class="badge badge-light">
                  {{ author.individual.firstAndLastName() }}
                </span>
              </div>
              <div v-for="article of publication.articles" :key="article.id" class="art-title">
                {{ article.title }}
                <span v-for="author of article.authors" :key="author.id" class="badge badge-light">
                  {{ author.individual.firstAndLastName() }}
                </span>
                <div>
                  <span v-for="keyword of article.keywords" :key="keyword.id" class="badge badge-dark">
                    #{{ keyword.keyword }}
                  </span>
                </div>
              </div>
            </li>
            <li v-if="showMenu">
              <a href @click.prevent="loadPublications()">
                <i class="fa fa-book-open"></i> Voir toutes les publications
              </a>
            </li>
            <li v-if="showMenu && hasPerm('publications.add_publication')">
              <router-link :to="{ name: 'publications-add', }">
                <i class="fa fa-plus-circle"></i> Nouvelle publication
              </router-link>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col cols="4">
        <div class="recent-items text-right">
          <h3>Accès récent</h3>
          <p v-if="recentAccess.length === 0">-</p>
            <div v-for="elt in recentAccess" :key="elt.id" class="recent-line">
              <router-link
                :to="{ name: 'publications-edit', params: {'publicationId': elt.id, }}"
              >
                {{ elt.fullName() }}
              </router-link>
            </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import store from '@/store'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import CounterLabel from '@/components/Controls/CounterLabel'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makePublication } from '@/types/publications'
import router from '@/router'

export default {
  name: 'publications-home',
  mixins: [BackendMixin],
  components: {
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      loadingName: 'publications-home',
      initLoadingName: 'publications-home-init',
      searchText: '',
      publications: [],
      publicationsDisplayed: false,
      selectedPublication: null,
      recentAccess: [],
      publicationsCount: 0,
      loaded: false,
      loadAll: false,
    }
  },
  watch: {
  },
  computed: {
    title() {
      return store.getters.config.publicationTitle
    },
    showPublications() {
      return this.publicationsDisplayed && !this.isLoading(this.loadingName)
    },
    showMenu() {
      return !this.publicationsDisplayed && !this.isLoading(this.loadingName)
    },
  },
  async mounted() {
    this.startLoading(this.initLoadingName)
    await this.loadPublicationsCount()
    await this.loadRecent()
    this.searchText = this.$route.query.search || ''
    this.loadAll = +(this.$route.query.all || '0')
    if (this.searchText) {
      await this.searchPublications(this.searchText)
    } else if (this.loadAll) {
      await this.loadPublications()
    }
    this.autoSelect()
    this.loaded = true
    this.endLoading(this.initLoadingName)
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    onSearch(evt) {
      evt.preventDefault()
      this.searchPublications(this.searchText)
      this.updateRoute()
    },
    updateRoute() {
      if (this.loaded) {
        const query = {}
        if (this.searchText) {
          query.search = this.searchText
        }
        if (this.loadAll) {
          query.all = '1'
        }
        if (this.selectedPublication) {
          query.selected = '' + this.selectedPublication.id
        }
        router.push({ path: this.$route.path, query: query, })
      }
    },
    async loadRecent() {
      let url = '/publications/api/publications/recently_accessed/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.recentAccess = resp.data.map(makePublication)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadPublicationsCount() {
      let url = '/publications/api/publications/count/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.publicationsCount = resp.data.count || 0
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async searchPublications(text) {
      this.publications = []
      if (text) {
        this.startLoading(this.loadingName)
        try {
          let url = '/publications/api/publications/search/?text=' + text
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          this.publications = resp.data.map(makePublication)
          this.publicationsDisplayed = true
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    async loadPublications() {
      this.searchText = ''
      this.loadAll = true
      this.updateRoute()
      this.startLoading(this.loadingName)
      try {
        let url = '/publications/api/publications/'
        const backendApi = new BackendApi('get', url)
        const resp = await backendApi.callApi()
        this.publications = resp.data.map(makePublication)
        this.publicationsDisplayed = true
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    viewPublication(publication) {
      this.selectedPublication = publication
      this.updateRoute()
    },
    autoSelect() {
      const selected = +(this.$route.query.selected || '0')
      if (selected) {
        const index = this.publications.map(elt => elt.id).indexOf(selected)
        if (index >= 0) {
          this.selectedPublication = this.publications[index]
        }
      }
    },
    onReset() {
      this.selectedPublication = null
      this.publications = []
      this.publicationsDisplayed = false
      this.searchText = ''
      this.loadAll = false
      this.updateRoute()
    },
  },
}
</script>

<style scoped lang="less">
.art-title {
  span {
    margin-right: 1px;
    margin-bottom: 1px;
    vertical-align: top;
  }
}
.app-menu.small-text li {
  font-size: 14px;
}
.selected {
  background: #cccccc;
}
</style>
